import React, { Component } from 'react';
import SEO from '../components/seo';

export default class ServiceRequests extends Component {

  state = {
    tenantName: "",
    propertyAddress: "",
    unitNumber: "",
    phoneNumber: "",
    email: "",
    serviceRequested: ""
  }

  render() {
    return (
      <div className="form-container">
        <SEO title="Service Requests" />
        
        <form className="form service-requests-form">
          <h2>Service Requests</h2>
          <p>I will hold a form for residents to submit service requests. Where do I send to?</p>
          <div className="input-group">
            <div className="input-field">
              <label htmlFor="tenantName">Your Name</label>
              <input type="text" name="tenantName"/>
            </div>
          </div>

          <div className="input-group">
            <div className="input-field flex-up-two">
              <label htmlFor="propertyAddress">Property Address</label>
              <input type="text" name="propertyAddress"/>
            </div>

            <div className="input-field flex-up-one">
              <label htmlFor="unitNumber">Unit Number</label>
              <input type="text" name="unitNumber"/>
            </div>
          </div>

          <div className="input-group">
            <div className="input-field">
              <label htmlFor="phoneNumber">Phone Number</label>
              <input type="phone" name="phoneNumber"/>
            </div>
          </div>

          <div className="input-group">
            <div className="input-field">
              <label htmlFor="email">Email</label>
              <input type="email" name="email"/>
            </div>
          </div>

          <div className="input-group">
            <div className="input-field">
              <label htmlFor="serviceRequested">Service Requested</label>
              <textarea name="serviceRequested"/>
            </div>
          </div>

          <button className="form-btn" type="submit">Submit Request</button>
        </form>
      </div>
    )
  }
}